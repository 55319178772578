import React from 'react';
import '../../styles/Services.css';
import {
  FaFacebookF,
  FaInstagram,
  FaTiktok,
  FaPlay
 } from 'react-icons/fa';
import Lottie from "lottie-react";
import Colors from '../../constants/Colors';
import Config from '../../constants/Config';



class ServicesTattoo extends React.PureComponent {


  constructor(props){
    super(props);

    this.state = {
    }

  }


  render(){

    return (
      <div className={`Services tattoo`} style={{flexDirection: 'column'}}>

        <div className="ServicesInfoSectionView">
          <div className="ServicesInfoSectionViewImageContainer">
            <div className="ServicesInfoSectionViewImage" style={{ backgroundImage: `url(${ process.env.PUBLIC_URL + '/globals/images/services_tattoo_0.jpg' })` }} />
          </div>

          <div className="ServicesInfoSectionDetails">
            <div className="ServicesInfoSectionDetailsTitle">Thinking about a tattoo?</div>
            <div className="ServicesInfoSectionDetailsContent">At Almighty Art Studios, we understand the commitment you’re making when getting a tattoo and the anxiety that sometimes comes along with it, so we want to make this the most pleasant experience that you can get while doing that. We use the most advanced tattoo equipment on the market today. From our tattoo machines and needles to even our stencil papers that are carefully hand picked for a better tattoo experience for you.</div>
          </div>
        </div>

        <div className="ServicesInfoSectionView">
          <div className="ServicesInfoSectionViewImageContainer">
            <div className="ServicesInfoSectionViewImage" style={{ backgroundImage: `url(${ process.env.PUBLIC_URL + '/globals/images/services_tattoo_1.jpg' })` }} />
          </div>

          <div className="ServicesInfoSectionDetails">
            <div className="ServicesInfoSectionDetailsTitle">What’s different at Almighty? (The tattoo process)</div>
            <div className="ServicesInfoSectionDetailsContent">Before we actually start tattooing into your skin, we go through a series of preparations, starting with designing your tattoo and bringing your idea to life on screen, so that you have a clear image of what you’re going to get. <br/> <br/> After designing phase, we print out your design onto a stencil sticker, ready to stick onto the skin. We do this also to place your tattoo on the perfect spot so that it flows with and actually enhances your body, like a tattoo should. <br/> <br/>
            We let the stencil dry on the skin and start tattooing. We only use Rotary tattoo Machines with Cartridge needles, meaning no buzzing sound that we all know to get us extra nervous, our tattoo machines are also know for causing less skin trauma and pain. With brands like Bishop Rotary and Kwadron, we are sure to give you only the best.
            </div>
          </div>
        </div>

        <div className="ServicesInfoSectionView">
          <div className="ServicesInfoSectionViewImageContainer">
            <div className="ServicesInfoSectionViewImage" style={{ backgroundImage: `url(${ process.env.PUBLIC_URL + '/globals/images/coconut_oil.jpg' })` }} />
          </div>

          <div className="ServicesInfoSectionDetails">
            <div className="ServicesInfoSectionDetailsTitle">What should I know? (The aftercare)</div>
            <div className="ServicesInfoSectionDetailsContent">Your tattoo is not done until it’s fully healed. When the artist is done, the aftercare process starts. We ask you to not forget to take care of your new tattoo for at least two weeks after you got the tattoo, where the old skin will dry and peal off for the new clear skin to emerge. Your new tattoo is comparable to a wound and if not properly cared for, can result, in worst cases, in an infection. If properly cared for, your tattoo will heal perfectly and result in a beautiful piece of art for years. </div>
          </div>
        </div>

        <div className="ServicesInfoSectionView">
          <div className="ServicesInfoSectionViewImageContainer">
            <div className="ServicesInfoSectionViewImage"></div>
          </div>

          <div className="ServicesInfoSectionDetails">
            <div className="ServicesInfoSectionDetailsTitle">The Proper Tattoo aftercare methods for us:</div>
            <div className="ServicesInfoSectionDetailsContent">Day 1-4: Fresh. <br/> <br/>
            - Keep tattoo dry (no ointments or oils) <br/>
            - Wash twice a day with water or antibacterial soap <br/>
            - Don’t go or stay too long in sunlight <br/>
            - Let your tattoo breath <br/>
            - NO swimming <br/>
            - NO excessive sweating (sports, gym etc.)
            <br/> <br/>
            Day 5-14: Pealing. <br/> <br/>
            - Apply thin layer of coconut oil when too dry (or non petroleum ointment) <br/>
            - Careful with clothes scrubbing on your pealing tattoo <br/>
            - Let scabs fall off by themselves <br/>
            - NO pulling on scabs
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default ServicesTattoo;
