import React from 'react';
import '../styles/Loaded.css';
import {
  FaFacebookF,
  FaInstagram,
  FaTiktok,
  FaPlay,
  FaNewspaper,
  FaTimes,
  FaStream
 } from 'react-icons/fa';
 import Lottie from "lottie-react";
 import Colors from '../constants/Colors';
 import Config from '../constants/Config';

 // Views
 import Footer from './components/Footer';

 import Home from './subs/Home';
 import About from './subs/About';
 import Services from './subs/Services';
 import ServicesTattoo from './subs/Services.Tattoo';
 import ServicesPiercing from './subs/Services.Piercing';

 import Portfolio from './subs/Portfolio';
 import Contact from './subs/Contact';
 import Shop from './subs/Shop';
 import News from './subs/News';

 import Panel from './subs/Panel';
 import Profile from './subs/Profile';

 import '../styles/Responsive.css';

// Animation
import animationLoading from "../animations/tattoo_loading.json";
import transitionLoading from "../animations/illuminati_eye.json";
import lightningStrike from "../animations/lightning_strike.json";

class Loaded extends React.PureComponent {


  constructor(props){
    super(props);

    this.state = {
      hoverText: 'ALMIGHTY ART STUDIOS',

      tab: 'home',
      tabData: null,

      isTransitioning: false,
      forceCovered: false,

      menuOpened: false,

      headerHide: false,

      imagePopup: {
        visible: false,
        image: {
          uri: ''
        }
      }
    }


    // Binders
    this.hoverMenu = this.hoverMenu.bind(this);
    this.portfolioImages = this.portfolioImages.bind(this);
    this.setTab = this.setTab.bind(this);
    this.getHeaderCoverage = this.getHeaderCoverage.bind(this);

    this.tabView = this.tabView.bind(this);
    this.listenToScroll = this.listenToScroll.bind(this);

    this.scroller = null;
    this.setScroller = this.setScroller.bind(this);
    this.setForceCovered = this.setForceCovered.bind(this);

    this.hideHeader = this.hideHeader.bind(this);
    this.setMenuOpened = this.setMenuOpened.bind(this);

    this.openImagePopup = this.openImagePopup.bind(this);
    this.renderImagePopup = this.renderImagePopup.bind(this);

    this.footerHidden = this.footerHidden.bind(this);
  }

  componentDidMount(){
    const pathName = window.location.pathname;

    if(pathName !== '/' && pathName !== ''){

      let pathNameParsed = pathName;
      let pathNameParsedData = '';
      if(pathName.includes('/profile/')){
        pathNameParsed = 'profile';
        pathNameParsedData = pathName.replace('/profile/', '');
      }


      switch(pathNameParsed){
        case "/about":
          this.setTab('about');
        break;
        case "/portfolio":
          this.setTab('portfolio');
        break;
        case "/services":
          this.setTab('services');
        break;
        case "/shop":
          this.setTab('shop');
        break;
        case "/contact":
          this.setTab('contact');
        break;

        case "profile":
          this.setTab('profile', pathNameParsedData);
        break;
        case "/panel":
          this.setTab('panel');
        break;
        default:

        break;
      }
    }

  }

  footerHidden(){
    let hidden = false;
    const { tab } = this.state;

    switch(tab){
      case "about":
        case "services":
          case "portfolio":
            case "shop":
              case "contact":
                case "panel":
        hidden = true;
      break;

      default:
        break;
    }


    return hidden;
  }

  setMenuOpened(menuOpened = !this.state.menuOpened){
    this.setState({
      menuOpened
    })
  }

  hideHeader(headerHide = this.state.hideHeader){
    this.setState({
      headerHide
    })
  }

  renderImagePopup(){
    const { imagePopup } = this.state;
    const { image } = imagePopup;

    return (
      <div className={`loadedPopupOverlay ${imagePopup.visible ? 'show' : ''}`} onClick={
        () => {
          this.openImagePopup('');
        }
      }>
        <div className="loadedPopupImageView">
            <span className="loadedPopupImageViewClose" style={{ backgroundColor: Colors.red }} onClick={
              () => {
                this.openImagePopup('');
              }
            }>
              <FaTimes />
            </span>
          { (image.uri != '') &&
            <img src={image.uri} draggable="false" className="loadedPopupImage" />
          }
        </div>
      </div>
    )
  }

  openImagePopup(image = ''){
    const { imagePopup } = this.state;

    this.setState({
      imagePopup: {
        visible: (image != '') ? true : false,
        image: {
          uri: (image != '') ? image : imagePopup.image.uri
        }
      }
    }, () => {

      if(image == ''){
        setTimeout( () => {
          this.setState({
            imagePopup: {
              visible: false,
              image: {
                uri: ''
              }
            }
          });
        }, 500);
      }

    })
  }

  setScroller(scroller){

    if(this.scroller){
      this.scroller.removeEventListener('scroll', this.listenToScroll);
      this.scroller = null;
    }else{
      this.scroller = scroller;
      this.scroller.addEventListener('scroll', this.listenToScroll);
    }

  }

  componentWillUnmount() {
    if(this.scroller){
      this.scroller.removeEventListener('scroll', this.listenToScroll)
    }
  }

  listenToScroll = () => {
    const { forceCovered } = this.state;

    if(this.scroller){
      const winScroll = this.scroller.scrollTop

      const height =
        this.scroller.scrollHeight -
        this.scroller.clientHeight
      const scrolled = winScroll / height


      this.setState({
        scrollPosition: winScroll,
      }, () => {

        if( winScroll > 175 && !forceCovered){
          this.setForceCovered(true);
        }else if(winScroll < 175 && forceCovered){
          this.setForceCovered(false);
        }
      });
    }

  }

  setTab(tab, data = null){

    this.setState({
      isTransitioning: true,
      forceCovered: false
    }, () => {

      let tabFinal = tab;
      if(data){
        tabFinal = tabFinal + '/' + data;
      }
      window.history.replaceState(null, "", "/" + tabFinal)
      this.setMenuOpened(false);
      setTimeout( () => {
        this.setState({
          tab: tab,
          tabData: data
        }, () => {

            setTimeout( () => {
              this.setState({
                isTransitioning: false
              }, () => {

              })
            }, 300)

        })
      }, 300)
    })
  }

  tabView(){
    const { tab, tabData, hoverText } = this.state;

    switch(tab){

      case "home":
        return (
          <Home
            hoverText={hoverText}
            setTab={this.setTab}
            openImagePopup={this.openImagePopup}
            hideHeader={this.hideHeader}
          />
        )
      break;

      case "about":
        return (
          <About
            setTab={this.setTab}
            setScroller={this.setScroller}
            openImagePopup={this.openImagePopup}
            hideHeader={this.hideHeader}
          />
        )
      break;

      case "services":
        return (
          <Services
            setTab={this.setTab}
            setScroller={this.setScroller}
            openImagePopup={this.openImagePopup}
            hideHeader={this.hideHeader}
          />
        )
      break;

      case "services.tattoo":
        return (
          <ServicesTattoo
            setTab={this.setTab}
            setScroller={this.setScroller}
            openImagePopup={this.openImagePopup}
            hideHeader={this.hideHeader}
          />
        )
      break;

      case "services.piercing":
        return (
          <ServicesPiercing
            setTab={this.setTab}
            setScroller={this.setScroller}
            openImagePopup={this.openImagePopup}
            hideHeader={this.hideHeader}
          />
        )
      break;

      case "shop":
        return (
          <Shop
            setTab={this.setTab}
            openImagePopup={this.openImagePopup}
            hideHeader={this.hideHeader}
          />
        )
      break;

      case "portfolio":
        return (
          <Portfolio
            setTab={this.setTab}
            openImagePopup={this.openImagePopup}
            hideHeader={this.hideHeader}
          />
        )
      break;

      case "contact":
        return (
          <Contact
            setTab={this.setTab}
            openImagePopup={this.openImagePopup}
            hideHeader={this.hideHeader}
          />
        )
      break;

      case "news":
        return (
          <News
            setTab={this.setTab}
            openImagePopup={this.openImagePopup}
            hideHeader={this.hideHeader}
          />
        )
      break;

      case "profile":
        return (
          <Profile
            setTab={this.setTab}
            openImagePopup={this.openImagePopup}
            hideHeader={this.hideHeader}
            data={tabData}
          />
        )
      break;

      case "panel":
        return (
          <Panel
            setTab={this.setTab}
            openImagePopup={this.openImagePopup}
            hideHeader={this.hideHeader}
          />
        )
      break;

      default:
      break;
    }


    return false;
  }

  portfolioImages(){
    const { portfolio_images } = this.state;

    const portfolios = (portfolio_images).map( (portfolio, index) => {

      return (
        <div className="homeCardItem">
          <div className="homeCardItemImage"
            style={{
              backgroundImage: `url('${portfolio.uri}')`
            }}
            >
            <div className="homeCardItemDate">{ portfolio.date }</div>
          </div>
        </div>
      )
    });


    return portfolios;
  }

  hoverMenu(menu){
    if(menu == ''){
      menu = 'ALMIGHTY ART STUDIOS';
    }
    this.setState({
      hoverText: menu
    })
  }

  setForceCovered(forceCovered){
    this.setState({
      forceCovered
    })
  }

  getHeaderCoverage(){
    const { tab, forceCovered } = this.state;

    let coverage = true;
    switch(tab){
        case "home":
        case "about":
        case "contact":
        case "services":
          coverage = false;
        break;
    }

    if(forceCovered){
      coverage = true;
    }

    return coverage;
  }

  render(){
    const { tab, hoverText, isTransitioning, headerHide, menuOpened } = this.state;
    const covered = this.getHeaderCoverage();

    return (
      <div className={`Loaded ${(hoverText != 'ALMIGHTY ART STUDIOS' && tab == 'home') && 'hovered'} ${(isTransitioning) && 'transitioning'} ${(covered) && 'covered'}`}>
        { this.renderImagePopup() }
        <div className={`HomeHeader ${(headerHide) ? 'hide' : ''}`}>

          <div className="HomeHeaderLogoView" onClick={
            () => {
              if(tab == 'home'){
                this.props.resetPage();
              }else{
                this.setTab('home')
              }
            }
          }>
            <img src={process.env.PUBLIC_URL + '/globals/logos/transparent.png'} className="HomeHeaderLogo" />
            <span className="HomeHeaderLogoText">ALMIGHTY <span className="HomeHeaderLogoTextRed">ART</span> STUDIOS</span>
          </div>

          <div className="HomeNavigation">
            <div className="HomeNavigationMenuBurger" onClick={
              () => {
                this.setMenuOpened();
              }
            }>
              { (menuOpened)
              ?
              <FaTimes />
              :
              <FaStream />
              }
            </div>
            <div className={`HomeNavigationMenu ${(menuOpened) ? 'show' : ''} `}>
              <span className={`HomeNavigationItem ${(tab == 'about') && 'selected'}`} onMouseOver={()=>{ this.hoverMenu('WHO ARE WE') }} onMouseOut={()=>{this.hoverMenu('')}} onClick={() => { this.setTab('about') }}>{ (tab == 'about') && <span className="HomeNavigationItemBall" /> }Who are we</span>
              <span className={`HomeNavigationItem ${(tab.includes('services')) && 'selected'}`} onMouseOver={()=>{ this.hoverMenu('OUR SERVICES') }} onMouseOut={()=>{this.hoverMenu('')}} onClick={() => { this.setTab('services') }}>{ (tab.includes('services')) && <span className="HomeNavigationItemBall" /> }Our services</span>
              <span className={`HomeNavigationItem ${(tab == 'portfolio') && 'selected'}`} onMouseOver={()=>{ this.hoverMenu('OUR PORTFOLIO') }} onMouseOut={()=>{this.hoverMenu('')}} onClick={() => { this.setTab('portfolio') }}>{ (tab == 'portfolio') && <span className="HomeNavigationItemBall" /> }Portfolio</span>
            {/*  <span className={`HomeNavigationItem ${(tab == 'shop') && 'selected'}`} onMouseOver={()=>{ this.hoverMenu('ALMIGHTY SHOP') }} onMouseOut={()=>{this.hoverMenu('')}} onClick={() => { this.setTab('shop') }}>{ (tab == 'shop') && <span className="HomeNavigationItemBall" /> }Shop <span className="HomeNavigationItemSub" style={{ color: Colors.red }}>COMING SOON</span></span> */}
              <span className={`HomeNavigationItem ${(tab == 'contact') && 'selected'}`} onMouseOver={()=>{ this.hoverMenu('COME FIND US') }} onMouseOut={()=>{this.hoverMenu('')}} onClick={() => { this.setTab('contact') }}>{ (tab == 'contact') && <span className="HomeNavigationItemBall" /> }Come find us</span>
              <div onClick={
                  () => {
                    this.setTab('panel');
                  }
                } className='HomeMobileOnly HomeMobileOnlyCC'>

                <div  className="HomeMobileOnlyCopyright">
                  <div className="HomeMobileOnlyCopyrightText">Copyright &copy; { new Date().getFullYear() } | All rights reserved.</div>
                </div>

              </div>
            </div>
          </div>
        </div>

        { (isTransitioning) &&
        <div className={`LoaderTransitioningLoader`}>
          <Lottie
            animationData={transitionLoading}
            loop={true}
            style={{
              width: 100,
              height: 100,
              pointerEvents: 'none'
            }}
            />
        </div>
        }

        <div className={`LoadedContent ${(menuOpened) ? 'menuOpened' : ''} `}>
        { this.tabView() }
        </div>

        <Footer
          hide={this.footerHidden()}
          setTab={this.setTab}
          />


      </div>
    );
  }
}

export default Loaded;
