import React from 'react';
import '../../styles/About.css';
import {
  FaFacebookF,
  FaInstagram,
  FaTiktok,
  FaPlay
 } from 'react-icons/fa';
import Lottie from "lottie-react";
import Config from '../../constants/Config';
import Footer from '../components/Footer';
import Colors from '../../constants/Colors';

import axios from 'axios';

class About extends React.PureComponent {


  constructor(props){
    super(props);

    this.state = {
      currentPage: 0,
      isMounted: false,

      content: []
    }

    this.fetchAbout = this.fetchAbout.bind(this);

  }

  componentDidMount() {
    this.setState({
      isMounted:true
    }, () => {
      this.fetchAbout()
    })
  }

  fetchAbout(){
    const { isMounted, content } = this.state;

    if(isMounted){

      axios.post(`${Config.api.url}/about/fetch`, null,
        {
          crossdomain: true,
          headers: {
            'api': Config.apiKeyDel
          }
        }
      )
      .then(function(json){
        const { success, response, data } = json.data;

        if(success){
          console.log("yess");
        }

      });
    }

  }

  render(){
    const { currentPage } = this.state;

    return (
      <div className={`About`}>

        <div className="AboutWrapper">
          <div className="AboutWrapperImage" ref={
            (refe) => {
              this.imageScroller = refe;
            }
          }>

            <div className="AboutInfoSectionImage">
              <img src={process.env.PUBLIC_URL + '/globals/images/clients.jpg'} className="AboutInfoSectionImageImage" />
            </div>

            <div className="AboutInfoSectionImage">
              <img src="https://images.pexels.com/photos/14242278/pexels-photo-14242278.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" className="AboutInfoSectionImageImage" />
            </div>

            <div className="AboutInfoSectionImage">
              <img src={process.env.PUBLIC_URL + '/globals/images/shop.jpg'} className="AboutInfoSectionImageImage" />
            </div>

            <div className="AboutInfoSectionImage">
              <img src="https://images.pexels.com/photos/4123730/pexels-photo-4123730.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" className="AboutInfoSectionImageImage" />
            </div>

            <div className="AboutInfoSectionImage">
              <img src={process.env.PUBLIC_URL + '/globals/images/coconut_oil.jpg'} className="AboutInfoSectionImageImage" />
            </div>




          </div>
          <div className="AboutWrapperDetails" ref={
            (ref) => {
              if(this.props.setScroller && typeof this.props.setScroller == 'function'){
                this.props.setScroller(ref);
              }
            }
          } onScroll={
            (event) => {
              let position = (event.nativeEvent.srcElement.scrollTop) + 200;
              const window_height = window.innerHeight;

              const currentPageNew = Math.floor(position / window_height);

              if(currentPageNew != currentPage){
                this.setState({
                  currentPage: currentPageNew
                }, () => {

                  if(currentPageNew < 5 && this.imageScroller){
                    const height = window_height * currentPageNew;
                    this.imageScroller.scrollTo({
                      top: height,
                      left: 0,
                      behavior: 'smooth'
                    });
                  }

                })
              }
            }
          }>

            <div className="AboutInfoSectionView" style={{ backgroundColor: '#1b1b1b', marginTop: 20, padding: 0 }}>

              <div className="AboutInfoSectionDetails">
                <div className="AboutInfoSectionDetailsTitle">The experience</div>
                <div className="AboutInfoSectionDetailsContent">Tattoos are a unique way to express your individuality and elevate your characteristic appearance.
  Getting your tattoo at Almighty Art Studios, is a transformative experience that transcends the ordinary and helps you embrace your individuality through a unique tattoo. <br/><br/>Almighty Art pieces are not for anyone, but for those who are bold and unafraid to be different.
  <br/>
  Join us to Transcend the Ordinary and Elevate Your Look.</div>
              </div>
            </div>


            <div className="AboutSectionWrapper">
              <div className="AboutSection">

                <div className="AboutSectionTitle">Want to see more and feel the Almighty experience?</div>
                <div className="AboutSectionText">We’d love to work with you on your next project.<br/>If you have any questions, feel free to fill in our form and we will answer shortly, send a message on WhatsApp for quicker response.<br/>If you’re nearby, feel free to pay us a visit.</div>

              </div>
            </div>

            <div className="AboutInfoSectionView">
              <div className="AboutInfoSectionDetails">
                <div className="AboutInfoSectionDetailsTitle">The artists</div>
                <div className="AboutInfoSectionDetailsContent">Our arists are tattoo-driven by heart and ready to work with you on your permanent art piece.</div>
                <div className="AboutInfoSectionArtists">

                  <div className="AboutInfoSectionArtistItem">
                    <div className="AboutInfoSectionArtistBack" style={{ backgroundImage: `url(${ process.env.PUBLIC_URL + '/globals/images/Gianni_back.png' })`}}></div>
                    <img className="AboutInfoSectionArtistFront" src={ process.env.PUBLIC_URL + '/globals/images/Gianni_front.png' } />
                    <div className="AboutInfoSectionArtistItemName">Gianni</div>
                  </div>

                  <div className="AboutInfoSectionArtistItem">
                    <div className="AboutInfoSectionArtistBack" style={{ backgroundImage: `url(${ process.env.PUBLIC_URL + '/globals/images/Molly_back.png' })`}}></div>
                    <div className="AboutInfoSectionArtistFrontWrapper">
                      <img className="AboutInfoSectionArtistFront" src={ process.env.PUBLIC_URL + '/globals/images/Molly_front.png' } />
                    </div>
                    <div className="AboutInfoSectionArtistItemName smaller">Molly</div>
                  </div>

                </div>
              </div>
            </div>

            <div className="AboutInfoSectionView">
              <div className="AboutInfoSectionDetails">
                <div className="AboutInfoSectionDetailsTitle">The studio</div>
                <div className="AboutInfoSectionDetailsContent">The most well known, professional Tattoo and Piercing Studio in Suriname at your service. Combined with the most advanced equipment in the industry today, we want to work with you to bring even your wildest fantasies to life, through your permanent art.<br/><br/>While still a young company, being established in August 2021, we provide unmatched quality and professionalism. We like to focus more on the client and making YOUR ideas come to life.</div>
                <div className="homeContentModalSignature about">Almighty Art Studios</div>
              </div>
            </div>

            <div className="AboutInfoSectionView">
              <div className="AboutInfoSectionDetails">
                <div className="AboutInfoSectionDetailsTitle">Our tattoo styles</div>
                <div className="AboutInfoSectionDetailsContent">Our style specialties are black and grey realism, neo-traditional, traditional, large scale geometric and fine line work, but we also do other styles with no problem at all.<br/><br/>We like to work with your ideas and make them into art on the skin, that’s why we design your Tattoo with the best designing equipment so that you can see and love your design before we even start tattooing.</div>

                <div className="AboutSectionOptions">
                  <span onClick={
                    () => {
                      this.props.setTab('about.styles');
                    }
                  } className="AboutSectionOptionsButton">EXPLORE OUR FAVORITE STYLES</span>
                </div>
              </div>
            </div>

            <div className="AboutInfoSectionView">
              <div className="AboutInfoSectionDetails">
                <div className="AboutInfoSectionDetailsTitle">Aftercare</div>
                <div className="AboutInfoSectionDetailsContent">Your tattoo or piercing is not done until it’s fully healed. We give you the most efficient aftercare instructions to guide you through your Healing process, so that your Art looks perfect forever.</div>

                <div className="AboutSectionOptions">
                  <span onClick={
                    () => {
                      this.props.setTab('about.aftercare');
                    }
                  } className="AboutSectionOptionsButton">HOW TO TAKE CARE OF YOUR TATTOO</span>
                </div>
              </div>
            </div>



            <Footer
              setTab={this.props.setTab}
              mode={'static'}
              />


          </div>
        </div>

      </div>
    );
  }
}

export default About;
