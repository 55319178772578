import React from 'react';
import '../../styles/Shop.css';
import {
  FaFacebookF,
  FaInstagram,
  FaTiktok,
  FaPlay
 } from 'react-icons/fa';
import Lottie from "lottie-react";
import Colors from '../../constants/Colors';
import Config from '../../constants/Config';
import shopAnimation from "../../animations/shop_loading.json";



class Shop extends React.PureComponent {


  constructor(props){
    super(props);

    this.state = {
    }

  }


  render(){

    return (
      <div className={`Shop`}>

        <Lottie
          animationData={shopAnimation}
          loop={true}
          className="ShopAnimation"
          />
          <div className="ShopTextView">
            <div className="ShopText">COMING<br/>SOON</div>
          </div>
      </div>
    );
  }
}

export default Shop;
