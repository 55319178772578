import React from 'react';
import '../../styles/Home.css';
import {
  FaFacebookF,
  FaInstagram,
  FaTiktok,
  FaPlay,
  FaTimes,
 } from 'react-icons/fa';
 import Lottie from "lottie-react";
 import axios from 'axios';
 import Config from '../../constants/Config';


class Home extends React.PureComponent {


  constructor(props){
    super(props);

    this.state = {

      playVideo: false,
      portfolio_images: []
    }


    // Binders
    this.portfolioImages = this.portfolioImages.bind(this);
    this.fetchPortfolioImages = this.fetchPortfolioImages.bind(this);
  }

  componentDidMount() {

    this.fetchPortfolioImages();

  }

  fetchPortfolioImages(){
    const { portfolio_images } = this.state;

    const api_url = Config.api.url + '/portfolio/home';

    const that = this;

    axios.post(api_url, null, {
        crossdomain: true,
        headers: {
          api: Config.api.key
        }
      })
      .then(function(json){
        const { success, response, data } = json.data;

        if(success){
          const portfolios = data.portfolios;

          that.setState({
            portfolio_images: portfolios,
          })
        }else{
        }
      })
      .catch(function(e){
        console.log(e.response)
      })
  }

  portfolioImages(){
    const { portfolio_images } = this.state;

    const portfolios = (portfolio_images).map( (portfolio, index) => {

      return (
        <div onClick={
          () => {

            this.props.openImagePopup(portfolio.image)
          }
        } className="homeCardItem" key={'port_'+ portfolio.id}>
          <div className="homeCardItemImage"
            style={{
              backgroundImage: `url('${portfolio.image}')`
            }}
            >
            <div className="homeCardItemDate">{ portfolio.date }</div>
          </div>
        </div>
      )
    });


    return portfolios;
  }


  render(){
    const { playVideo } = this.state;
    const { hoverText } = this.props;

    return (
      <div className={`Home ${hoverText != 'ALMIGHTY ART STUDIOS' && 'hovered'} ${ (playVideo) && 'focused'}`}>
        <div className={`homeHoverTextWrapper ${hoverText != 'ALMIGHTY ART STUDIOS' && 'show'}`}>
          <div className="homeHoverText">{ hoverText }</div>
        </div>


        <div className="homeVideo">
          <video autoPlay muted loop playsInline className="homeVideoVideo">
            <source src={process.env.PUBLIC_URL + '/globals/videos/amb.mp4'} type="video/mp4" />
          </video>
          <div className="homeVideoOverlay" />
        </div>

        <div className="homeContent">
          <div className="homeContentModal">
            <div className="homeContentModalTitle">Free your Inner God.</div>
            <div className="homeContentModalText">through Divine Artistry</div>
            <div className="homeContentModalOptions">
              <span className="homeContentModalOptionsButton" onClick={
                () => {
                  this.props.setTab('about')
                }
              }>Get to know us better</span>
            </div>
          </div>

          <div className="homeContentModalPlay">
            <div className="homeContentModalPlayButton"
              onClick={
                () => {
                  this.setState({
                    playVideo: !playVideo
                  }, () => {

                    this.props.hideHeader(!playVideo);
                  })
                }
              }
              >
              { (playVideo)
              ?
              <FaTimes />
              :
              <FaPlay />
              }
            </div>
            <div className="homeContentModalPlayButtonBig">
              <div className="homeContentModalPlayButtonMedium">
                <div className="homeContentModalPlayButtonSmall" />
              </div>
            </div>
          </div>

        </div>

        <div className="homeCardView">

          <div className="homeCardHover">
            <div className="homeCardHeader">
              <div className="homeCardHeaderTitle">Portfolio</div>
              <div className="homeCardHeaderLine" />
            </div>

            <div className="homeCardWrapper">


            </div>

            <div className="homeCardOptions">
              <span className="homeCardOptionsButton" onClick={
                () => {
                  this.props.setTab('portfolio')
                }
              }>Browse through our portfolio</span>
            </div>
          </div>

        </div>



      </div>
    );
  }
}

export default Home;
