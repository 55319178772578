import React from 'react';
import '../../styles/Portfolio.css';
import axios from 'axios';
import {
  FaExpandAlt,
  FaChevronRight,
  FaArrowUp
 } from 'react-icons/fa';
import Lottie from "lottie-react";
import Config from '../../constants/Config';
import Footer from '../components/Footer';


class Portfolio extends React.PureComponent {


  constructor(props){
    super(props);

    this.state = {
      isMounted: false,
      categorySelected: 0,
      categories: [],
      portfolio_raw: [],
      portfolio: []
    }

    // Binders
    this.renderCategories = this.renderCategories.bind(this);
    this.renderPortfolio = this.renderPortfolio.bind(this);

    this.filterItems = this.filterItems.bind(this);

    this.fetchPortfolio = this.fetchPortfolio.bind(this);
  }

  componentDidMount() {
    this.setState({
      isMounted: true
    }, () => {
      this.fetchPortfolio();
    })
  }

  filterItems() {
    const { portfolio_raw, categorySelected } = this.state;

    let portfolioFiltered = portfolio_raw;

    if(categorySelected > 0){
      portfolioFiltered = (portfolio_raw).filter( (port, ind) => port.category.id == categorySelected);
    }


    return portfolioFiltered;
  }

  fetchPortfolio(cat_id = 0){
    const { categories, portfolios } = this.state;

    const api_url = Config.api.url + '/portfolio/fetch';

    const that = this;


    axios.post(api_url, {cat_id}, {
        crossdomain: true,
        headers: {
          api: Config.api.key
        }
      })
      .then(function(json){
        const { success, response, data } = json.data;

        console.log(json.data);

        if(success){
          const portfolios = data.portfolios;
          const categories = data.categories;

          that.setState({
            portfolio_raw: portfolios,
            portfolio: portfolios,
            categories: [{ name: 'All', id: 0 }, ...categories]
          })
        }else{
        }
      })
      .catch(function(e){
        console.log(e.response)
      })
  }

  renderCategories(){
    const { categories, categorySelected } = this.state;

    const portfolioCategories = (categories).map( (category, index) => {

      const isSelected = (category.id == categorySelected);
      return (
        <div key={'cat_' + category.id} className={`CategoryItem ${(isSelected) && 'selected'}`} onClick={
          () => {
            this.setState({
              categorySelected: category.id
            }, () => {
              this.fetchPortfolio(category.id);
            })
          }
        } >
          <div className="CategoryItemText">{ category.name }</div>
          <FaChevronRight className="CategoryItemArrow" />
        </div>
      )
    })

    return (
      <div className="PortfolioCategories">
        { portfolioCategories }
      </div>
    );
  }

  renderPortfolio(){
    const { portfolio } = this.state;

    const portfolioItems = (portfolio).map( (port, index) => {
      return (
        <div className="PortfolioItem" onClick={
          () => {
            this.props.openImagePopup(port.image);
          }
        } key={'portfolio_'+port.id}>
          <div className="PortfolioItemImage" style={{
            backgroundImage: `url('${port.image}')`
          }} />
          <div className="PortfolioOverlay">
            <div className="PortfolioOverlayExpand">
              <FaExpandAlt />
            </div>
          </div>
        </div>
      )
    })

    return portfolioItems;
  }

  render(){
    const { categories, categorySelected } = this.state;

    const headerTitle = (categorySelected) ? (categories.find((c,i)=> c.id == categorySelected))?.name : 'Some of our work.';

    return (
      <>

        <span className="GoUpButton" onClick={
          () => {
            if(this.portfolioScroller){
              this.portfolioScroller.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
              });
            }
          }
        }>
          <FaArrowUp />
        </span>

        <div className={`Portfolio`} ref={
          (refe) => {
            this.portfolioScroller = refe;
          }
        }>
          <div className="PortfolioSection">

            <div className="PortfolioSectionTitle">{ headerTitle }</div>
            <div className="PortfolioSectionText">We have built this portfolio based on community popularity.</div>

            { this.renderCategories() }

            <div className="PortfolioSectionItems">
              { this.renderPortfolio() }
            </div>

          </div>

          <Footer
            hidden={false}
            setTab={this.props.setTab}
            mode={'static'}
            />

        </div>
      </>
    );
  }
}

export default Portfolio;
