import React, { useState, useRef, useEffect } from 'react';
import '../../styles/Panel.css';
import {
  FaArrowLeft,
  FaArrowRight,
  FaLock,
} from 'react-icons/fa';
import Lottie from "lottie-react";
import Colors from '../../constants/Colors';
import Config from '../../constants/Config';
import axios from 'axios';
import PanelPortfolio from './panel/Portfolio';
import { useCookies } from 'react-cookie';

function Panel(props) {
  const [ view, setView ] = useState('menu');
  const [ viewData, setViewData ] = useState();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ account, setAccount ] = useState(null);
  const inputPhone = useRef();
  const inputPassword = useRef();
  const [cookies, setCookie, removeCookie] = useCookies(['al_account']);

  useEffect( () => {
    
    checkLogin();

  }, []);

  const setTab = (tab) => {
    
    switch(tab){
      case "cards":
      case "profiles":
      case "portfolio":
       setView(tab);
      break;

      default:
        if(view != 'menu'){
          setViewData();
          setView('menu');
        }
      break;
    }

  };

  const checkLogin = () => {
    if(cookies['al_account']){
      const acc = cookies['al_account'];

      setAccount(acc);
    }
  }

  const loginAction = () => {
    const phone = inputPhone.current?.value;
    const password = inputPassword.current?.value;

    if(!isLoading){

      setIsLoading(true);
      const api_url = Config.api.url + '/admin/login';
      axios.post(api_url, { 
        phone: phone,
        password: password
       }, {
          crossdomain: true,
          headers: {
            api: Config.api.key
          }
        })
        .then(function(json){
          const { success, response, data } = json.data;
  
          setIsLoading(false);
          if(success){
            const account = data.account;

            if(account){
              setAccount(account);
              setCookie('al_account', JSON.stringify(account), { path: '/' });
            }
  
          }else{
          }
        })
        .catch(function(e){
          setIsLoading(false);
        })

    }

  }

  const contentTitle = () => {
    let title = 'Admin Panel';

    if(viewData && viewData.title != ''){

      title = viewData.title;

    }else{

      switch(view){
        case "cards":
          title = 'Almighty Loyalty Cards';
        break;
        case "profiles":
          title = 'Profiles';
        break;
        case "portfolio":
          title = 'Portfolio';
        break;
  
        default:
      }
    }

    return title;
  }


  const contentView = () => {

    if(account){
      switch(view){
        case "cards":
  
        break;
        case "profiles":
  
        break;
        case "portfolio":
          return (
            <PanelPortfolio 
              {...props}
              account={account}
              setViewData={setViewData}
            />
          )
        break;
  
        default:
          return (
            <div className="PanelContent">
  
              <div className="PanelTitle">Secret menu</div>
              <div onClick={() => {
                setTab('cards');
              }} className="PanelButton">
                <div className="PanelButtonText">Almighty Loyalty Cards</div>
                <FaArrowRight className="PanelButtonIcon" />
              </div>
              <div onClick={() => {
                setTab('profiles');
              }} className="PanelButton">
                <div className="PanelButtonText">Profiles</div>
                <FaArrowRight className="PanelButtonIcon" />
              </div>
              <div onClick={() => {
                setTab('portfolio');
              }} className="PanelButton">
                <div className="PanelButtonText">Portfolio</div>
                <FaArrowRight className="PanelButtonIcon" />
              </div>
              <div onClick={() => {
                setTab('content');
              }} className="PanelButton Disabled">
                <div className="PanelButtonText">Content</div>
                <FaArrowRight className="PanelButtonIcon" />
              </div>
              <div onClick={() => {
                setTab('artists');
              }} className="PanelButton Disabled">
                <div className="PanelButtonText">Artists</div>
                <FaArrowRight className="PanelButtonIcon" />
              </div>
  
              <div className="PanelTitle">Account</div>
              <div onClick={
                () => {
                  removeCookie('al_account', { path: '/' });
                  setAccount(null);
                }
              } className="PanelButton">
                <div className="PanelButtonText">Log out</div>
                <FaLock className="PanelButtonIcon" style={{ fontSize: 10 }} />
              </div>
            </div>
          )
        break;
      }
    }else{
      return (
        <div className="PanelLoginView">

          <div className="PanelLogin">
            <div className="PanelLoginItem">
              <div className="PanelLoginItemPre">Phone</div>
              <input
               ref={inputPhone}
               placeholder={'8765432'}
               className="PanelLoginItemInput"
              />
            </div>
            <div className="PanelLoginItem">
              <div className="PanelLoginItemPre">Password</div>
              <input
               ref={inputPassword}
               placeholder={'*******'}
               type={'password'}
               className="PanelLoginItemInput"
              />
            </div>
            { (!isLoading) &&
            <div onClick={
              () => {
                if(!isLoading){
                  loginAction();
                }
              }
            } className="PanelLoginButton">Log in</div>
            }
          </div>

        </div>
      )
    }

    

    return false;
  }


  return (
    <>
     <div className="PanelNavigation">
          {(view != 'menu') &&
          <div onClick={() => { 
            
            if(viewData){
              viewData.back();
              setViewData();
            }else{
              setTab('menu');
            }
            
          }} className="AlbumNavigationBack">
            <FaArrowLeft />
          </div>
          }

          <div className="AlbumNavigationTitle">{ contentTitle() }</div>
        </div>

      <div className="Panel">
       
        { contentView() }
        
      </div>
    </>
  );
}

export default Panel;
