import React from 'react';
import '../../styles/Services.css';
import {
  FaFacebookF,
  FaInstagram,
  FaTiktok,
  FaPlay
 } from 'react-icons/fa';
import Lottie from "lottie-react";
import Colors from '../../constants/Colors';
import Config from '../../constants/Config';



class ServicesPiercing extends React.PureComponent {


  constructor(props){
    super(props);

    this.state = {
    }

  }


  render(){

    return (
      <div className={`Services tattoo`} style={{flexDirection: 'column'}}>

        <div className="ServicesInfoSectionView">
          <div className="ServicesInfoSectionViewImageContainer">
            <div className="ServicesInfoSectionViewImage"></div>
          </div>

          <div className="ServicesInfoSectionDetails">
            <div className="ServicesInfoSectionDetailsTitle">So you also want a piercing?</div>
            <div className="ServicesInfoSectionDetailsContent">No problem! Getting a piercing at Almighty is much faster than getting a tattoo. We use sterile piercing needles. We don’t use piercing guns as these can cause significant tissue damage, what medically is referred to as “blunt force trauma”. At the least, it can result in significant pain and swelling for the client, at the most in scarring and potentially increased incidence of auricular chondritis, a severe tissue disfigurement.</div>
          </div>
        </div>

        <div className="ServicesInfoSectionView">
          <div className="ServicesInfoSectionViewImageContainer">
            <div className="ServicesInfoSectionViewImage"></div>
          </div>

          <div className="ServicesInfoSectionDetails">
            <div className="ServicesInfoSectionDetailsTitle">What’s it like? (The piercing process)</div>
            <div className="ServicesInfoSectionDetailsContent">We start the process by sterilizing the area, then marking the placement of the piercing hole or holes. When you’re happy with the placement, we can pierce the hole and apply the jewelry. This only takes a few minutes and you’re done. </div>
          </div>
        </div>

        <div className="ServicesInfoSectionView">
          <div className="ServicesInfoSectionViewImageContainer">
            <div className="ServicesInfoSectionViewImage"></div>
          </div>

          <div className="ServicesInfoSectionDetails">
            <div className="ServicesInfoSectionDetailsTitle">Why remember? (The aftercare)</div>
            <div className="ServicesInfoSectionDetailsContent">As with your tattoo, your Piercing is not done until it’s fully healed. Piercings take much longer to heal than tattoos. Depending on the piercing, healing time can be from 2-6 months. As it takes a while to heal, you can easily forget to keep taking care of your piercing. Good aftercare is essential for a perfect piercing.</div>
          </div>
        </div>

        <div className="ServicesInfoSectionView">
          <div className="ServicesInfoSectionViewImageContainer">
            <div className="ServicesInfoSectionViewImage"></div>
          </div>

          <div className="ServicesInfoSectionDetails">
            <div className="ServicesInfoSectionDetailsTitle">The Proper Piercing aftercare methods for us:</div>
            <div className="ServicesInfoSectionDetailsContent">- Wash your hands thoroughly before touching piercing area.
            - Either spray the saline directly on the piercing to flush/soak the piercing.
            - You may need to use a q-tip to clean away any debris or crusties that may have collected on the jewelry around the piercing.
            - Some tenderness or discomfort in the area of a new piercing can be expected for several days or longer. Discoloration (redness), swelling or itching during healing process are also fairly common.
            - Piercings will go through “ups and downs” while healing. They may seem healed for a while, and then regress. The key is to continue your cleaning routine throughout initial healing time.
            - Check to make sure your jewelry is still tight!
            </div>
          </div>
        </div>

        <div className="ServicesInfoSectionView">
          <div className="ServicesInfoSectionViewImageContainer">
            <div className="ServicesInfoSectionViewImage"></div>
          </div>

          <div className="ServicesInfoSectionDetails">
            <div className="ServicesInfoSectionDetailsTitle">When can I go crazy again? (Healing time)</div>
            <div className="ServicesInfoSectionDetailsContent">Most piercings take around 2-6 months to heal. But each piercing differs in healing time. We’ll consult you on the healing of your piercing depending on the one you get.</div>
          </div>
        </div>


      </div>
    );
  }
}

export default ServicesPiercing;
