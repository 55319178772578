import React from 'react';
import {
  FaFacebookF,
  FaInstagram,
  FaTiktok,
  FaPlay,
  FaNewspaper
 } from 'react-icons/fa';
import Lottie from "lottie-react";
import animationLoading from "../../animations/tattoo_loading.json";



class Footer extends React.PureComponent {


  constructor(props){
    super(props);

    this.state = {
    }

  }

  getClassName(mode){
    let className = 'homeFooter';

    switch(mode){
      case "static":
        className = 'homeFooterStatic';
      break;
    }

    return className;
  }


  render(){
    const hidden = (this.props.hide);
    const mode = (this.props.mode && this.props.mode != null) ? this.props.mode : 'float';

    const className = this.getClassName(mode);

    return (
    <div className={`${className} ${(hidden) ? 'hidden' : '' }`}>
      <div className="homeFooterSocials">
        <a className="homeFooterSocialItem fb" href="https://www.facebook.com/Almighty.art.studios" target="_blank">
          <FaFacebookF />
        </a>
        <a className="homeFooterSocialItem ig" href="https://www.instagram.com/almighty.art.studios" target="_blank">
          <FaInstagram style={{ fontSize: '23px' }} />
        </a>
        <a className="homeFooterSocialItem tt" href="https://www.tiktok.com/@almighty.arts?lang=en" target="_blank">
          <FaTiktok />
        </a>
      </div>
      <div onClick={
          () => {
            this.props.setTab('panel');
          }
        } className="homeFooterCopyright">
        <div className="homeFooterCopyrightLine" />
        <div className="homeFooterCopyrightText">Copyright &copy; { new Date().getFullYear() } | All rights reserved.</div>

        <div className="homeFooterAnimation">
          <Lottie
            animationData={animationLoading}
            loop={true}
            style={{
              width: 100,
              height: 100
            }}
            />
        </div>


      </div>
    </div>
    );
  }
}

export default Footer;
