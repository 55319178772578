import React from 'react';
import '../../styles/Services.css';
import {
  FaFacebookF,
  FaInstagram,
  FaTiktok,
  FaPlay
 } from 'react-icons/fa';
import Lottie from "lottie-react";
import Colors from '../../constants/Colors';
import Config from '../../constants/Config';



class Services extends React.PureComponent {


  constructor(props){
    super(props);

    this.state = {
      services: [
        {
          image: 'https://copyrightalliance.org/wp-content/uploads/2020/12/iStock-1081274420-scaled-1.jpg',
          view: 'services.tattoo',
          active: true,
          title: (<>TA<span style={{ color: Colors.red }}>TT</span>OO</>)
        },
        {
          image: 'https://familydoctor.org/wp-content/uploads/2006/05/2145544_l.jpg',
          view: 'services.piercing',
          active: true,
          title: (<>PIER<span style={{ color: Colors.red }}>C</span>ING</>)
        },
        {
          image: '',
          view: 'services.modification',
          active: false,
          title: (<>TATTOO <span style={{ color: Colors.red }}>REMOVAL</span><br/><span style={{ fontSize: 13, letterSpacing: -0.2, color: '#EF7B0C' }}>COMING VERY SOON</span></>)
        }
      ]
    }


    // Binders
    this.renderServices = this.renderServices.bind(this);

  }


  renderServices(){
    const { services } = this.state;

    const servicesItems = (services).map( (service, index) => {
      return (
        <div className="ServiceItem"
          onClick={
            () => {
              if(service.active){
                this.props.setTab(service.view);
              }
            }
          }
          style={{
            backgroundImage: `url('${service.image}')`,
            cursor: (service.active) ? 'pointer' : 'not-allowed'
          }} key={'service_'+index}>

          <div className="ServiceItemText">{ service.title }</div>

        </div>
      )
    })

    return servicesItems;
  }

  render(){

    return (
      <div className={`Services`}>

        { this.renderServices() }

      </div>
    );
  }
}

export default Services;
