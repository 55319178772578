import React from 'react';
import '../../styles/Services.css';
import {
  FaFacebookF,
  FaInstagram,
  FaTiktok,
  FaPlay
 } from 'react-icons/fa';
import Lottie from "lottie-react";
import Colors from '../../constants/Colors';
import Config from '../../constants/Config';



class News extends React.PureComponent {


  constructor(props){
    super(props);

    this.state = {
    }

  }


  render(){

    return (
      <div className={`News`}>


      </div>
    );
  }
}

export default News;
