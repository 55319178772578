const Colors = {
  white: '#fff',
  darkGray: '#1b1b1b',

  lightGray: '#efefef',

  red: '#ad0001'
}

export default Colors;
