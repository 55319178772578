import React from 'react';
import './styles/App.css';

import Loaded from './views/Loaded';
import { CookiesProvider } from 'react-cookie';

class App extends React.PureComponent {

  constructor(props){
    super(props);

    this.state = {
      isLoading: true,
      isMounted: false,
      isLoaded: false,
      isLoadedFinal: false,
    }

    // Binders
    this.triggerAnimation = this.triggerAnimation.bind(this);
    this.componentView = this.componentView.bind(this);
    this.resetPage = this.resetPage.bind(this);
    this.startLoader = this.startLoader.bind(this);
  }


  resetPage(){
    this.setState({
      isLoading: true,
      isMounted: false,
      isLoaded: false,
      isLoadedFinal: false,
    }, () => {
      this.startLoader();
    })
  }

  triggerAnimation(){
    const { isMounted } = this.state;

    if(isMounted){
      this.setState({
        isLoading: true
      })
    }
  }

  startLoader(){
    // Trigger animation
    setTimeout( () => {
      // this.triggerAnimation();
      this.setState({
        isLoading: false
      }, () => {

        setTimeout( () => {
          this.setState({
            isLoaded: true
          }, () => {

            setTimeout( () => {
              this.setState({
                isLoadedFinal: true
              })
            }, 500);
          })
        }, 500);
      })
    }, 1500)
  }

  componentDidMount(){
    this.setState({
      isMounted: true
    }, () => {

      this.startLoader();

    })
  }

  componentWillUnmount(){
    this.setState({
      isMounted: false
    }, () => {


    })
  }

  // Views
  componentView(){
    const { view } = this.state;

    switch(view){
      default:
        return (
          <Loaded
            resetPage={this.resetPage}

          />
        )
    }


    return false;
  }

  render(){
    const { isMounted, isLoading, isLoaded, isLoadedFinal } = this.state;


    return (
      <CookiesProvider>
      { (!isLoadedFinal) &&
        <div className={`Loading ${ (!isLoading) && 'hide' }`}>
          <img src={process.env.PUBLIC_URL + '/globals/logos/transparent.png'} className="LoadingLogo" />
        </div>
      }


      <div className={`App ${isLoaded && 'show'}`}>

        { this.componentView() }

      </div>
      </CookiesProvider>
    );
  }
}

export default App;
