import React, { useState, useRef, useEffect } from 'react';
import {
  FaArrowLeft,
  FaArrowRight,
  FaChevronRight,
  FaLock,
  FaPlusSquare
} from 'react-icons/fa';
import Lottie from "lottie-react";
import Colors from '../../../constants/Colors';
import Config from '../../../constants/Config';
import axios from 'axios';

function Portfolio(props) {
  const [ categories, setCategories ] = useState([]);
  const [ categorySelected, setCategorySelected ] = useState();
  const { account, setViewData, openImagePopup } = props;

  const fetchItems = () => {

    const api_url = Config.api.url + '/admin/portfolio/fetch';
    axios.post(api_url, { account_id: account.id }, {
        crossdomain: true,
        headers: {
          api: Config.api.key
        }
      })
      .then(function(json){
        const { success, response, data } = json.data;


        if(success){
          const categoriesFinal = data.categories;


          if(categoriesFinal.length >= 0){
            setCategories(categoriesFinal);
          }

        }else{
        }
      })
      .catch(function(e){
        console.log(e.response)
      })

  }

  const items = () => {

    if(categorySelected){
      
      return (
        <>
          <div onClick={
            () => {

            }
          } className="PaneldAddItem">
            <div className="Icon">
              <FaPlusSquare />
            </div>
          </div>
          {
            (categorySelected.images).map( (image, index) => {

              const image_url = image.url;
    
              return (
                <div onClick={ () => {
                  
                   openImagePopup(image_url)
                  }
                } className="PhotoItem" key={'photo_'+ index} style={{ backgroundImage: `url('${image_url}')` }}>
                
    
                </div>
              )
            })
          }
        </>
      );

    }else{

      return categories.map( (category, index) => {

        const image = (category.images.length > 0) ? category.images[0].url : '';
  
        return (
          <div onClick={
            () => {
              setCategorySelected(category);
              setViewData({
                title: category.name,
                back: () => {
                  setCategorySelected();
                }
              });
            }
          } className="AlbumItem" key={'cat_'+ index}>
            <div className="AlbumItemImage Smaller" style={{ backgroundImage: `url('${image}')` }} />
            <div className="AlbumItemDetails">
              <div className="AlbumItemDetailsName">{ category.name }</div>
              <div className="AlbumItemDetailsPhotos">{ category.images.length + ' photo(s)' }</div>
            </div>
            <FaChevronRight style={{ fontSize: 10 }} />
          </div>
        )
        });
    }

    return false;
  }

  useEffect( () => {
    fetchItems();


  }, [])
  
  return (
    <div className="PanelPortfolio">
      
      { items() }
      
    </div>
  );
}

export default Portfolio;
