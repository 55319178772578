import React from 'react';
import '../../styles/Profile.css';
import {
  FaArrowLeft,
  FaCheckCircle
 } from 'react-icons/fa';
import Lottie from "lottie-react";
import Colors from '../../constants/Colors';
import Config from '../../constants/Config';
import axios from 'axios';



class Profile extends React.PureComponent {


  constructor(props){
    super(props);

    this.state = {
      tab: 0,
      albumSelected: null,
      isLoading: false,
      isValid: false,
      profile: null,
      albums: [],
      images: []
    }

    this.renderContent = this.renderContent.bind(this);
    this.fetchProfile = this.fetchProfile.bind(this);

  }

  fetchProfile(){
    const { data } = this.props;
    const { isLoading } = this.state;

    if(!isLoading && data != ''){
      const api_url = Config.api.url + '/profile/fetch';
      const that = this;
      axios.post(api_url, { token: data }, {
          crossdomain: true,
          headers: {
            api: Config.api.key
          }
        })
        .then(function(json){
          const { success, response, data } = json.data;


          if(success){
            const albums = data.albums;
            const images = data.images;
            const profile = data.profile;

            if(profile && profile.one_time){
              window.history.replaceState(null, "", "/")
            }

            that.setState({
              albums: albums,
              images: images,
              profile: profile,
              isValid: true,
              isLoading: false,
            })
          }else{

            that.setState({
              albums: [],
              images: [],
              profile: null,
              isValid: false,
              isLoading: false,
            })
          }
        })
        .catch(function(e){
          that.setState({
            albums: [],
            images: [],
            isValid: false,
            isLoading: false,
          })
        })
    }

  }

  componentDidMount(){
    this.props.hideHeader(true);
    this.fetchProfile();
  }

  renderContent = () => {
    const { tab, albums, images, albumSelected } = this.state;

    
    switch(tab){
      case 0:

        if(albumSelected){

          return (
            <>
              <div className="AlbumNavigation">
                <div onClick={
                  () => {
                    this.setState({
                      albumSelected: null
                    })
                  }
                } className="AlbumNavigationBack">
                  <FaArrowLeft />
                </div>

                <div className="AlbumNavigationTitle">{ albumSelected.name }</div>

              </div>

              {
                (albumSelected.images).map( (image, index) => {

                  const image_url = image.url;

                  return (
                    <div onClick={ () => {
                      
                       this.props.openImagePopup(image_url)
                      }
                    } className="PhotoItem" key={'photo_'+ index} style={{ backgroundImage: `url('${image_url}')` }}>
                    

                    </div>
                  )
                })
              }

            </>
          )

        }else{
          return albums.map( (album, index) => {

            const image = (album.images.length > 0) ? album.images[0].url : '';

            return (
              <div onClick={
                () => {
                  this.setState({
                    albumSelected: album
                  })
                }
              } className="AlbumItem" key={'album_'+ index}>
                <div className="AlbumItemImage" style={{ backgroundImage: `url('${image}')` }} />
                <div className="AlbumItemDetails">
                 <div className="AlbumItemDetailsName">{ album.name }</div>
                 <div className="AlbumItemDetailsPhotos">{ album.images.length + ' photo(s)' }</div>
                </div>
  
              </div>
            )
          });
        }
        
      break;
      case 1:
       return images.map( (image, index) => {

         const image_url = image.url;

          return (
            <div onClick={ () => {
                      
              this.props.openImagePopup(image_url)
             }
           } className="PhotoItem" key={'photo_'+ index} style={{ backgroundImage: `url('${image_url}')` }}>
            

            </div>
          )
        });
      break;

      default:
    }

  }

  render(){
    const { tab, albumSelected, isValid, profile, images  } = this.state;

    if(!isValid){
      return false;
    }


    return (
      <>
      

      <div className="ProfileLogo">
          <img src={process.env.PUBLIC_URL + '/globals/logos/transparent.png'} className="ProfileLogoImage" />
        </div>
      <div className={`Profile`}> 
        
        <div className="ProfileCarousel">
          { (images.length > 0) &&
          <div className="ProfileCarouselImage" style={{ backgroundImage: `url('${ images[images.length - 1].url }')` }} />
          }
        </div>
        <div className="ProfileHeaderWrapper">

          <div className="ProfileHeader">
            <div className="ProfileHeaderPlan">
              <div className="ProfileHeaderPlanCard">{ profile.plan }</div>
            </div>

            <div className="ProfileHeaderName">{ profile.name } <FaCheckCircle className="ProfileCheck" /></div>
            <div className="ProfileHeaderSub">Member until { profile.expiration_date.date }</div>

          </div>

          <div className="ProfileNavigation">
            <div onClick={
              () => {
                this.setState({
                  tab: 0,
                  albumSelected: (tab == 0) ? null : albumSelected
                })
              }
            } className={`ProfileNavigationItem ${ (tab == 0) ? 'Selected' : '' }`}>Albums</div>
            <div  onClick={
              () => {
                this.setState({
                  tab: 1
                })
              }
            } className={`ProfileNavigationItem ${ (tab == 1) ? 'Selected' : '' }`}>All Photos</div>
          </div>
        </div>

        <div className="ProfileContent">

            { this.renderContent() }

        </div>

      </div>
      </>
    );
  }
}

export default Profile;
